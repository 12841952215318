import 'ol/ol.css';
import {Style, Icon} from 'ol/style';
import {Feature, Map, Overlay, View} from 'ol/index';
import {XYZ, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Point} from 'ol/geom';
import {useGeographic} from 'ol/proj';
import {FullScreen, defaults as defaultControls} from 'ol/control';

useGeographic();
const asxLocation = [10.235300, 48.447400];
const asxPoint = new Point(asxLocation);

const attributions =
    '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';

const rasterLayer = new TileLayer({
    source: new XYZ({
        attributions: attributions,
        url: 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
        maxZoom: 18
    })
});

const iconFeature = new Feature({
    geometry: asxPoint,
    name: 'ASX'
});

const iconStyle = new Style({
    image: new Icon({
        anchor: [20, 60],
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        src: 'assets/aex_mapslogo.png',
    }),
});
iconFeature.setStyle(iconStyle);

const vectorSource = new VectorSource({
    features: [iconFeature],
});
const vectorLayer = new VectorLayer({
    source: vectorSource,
});

const map = new Map({
    controls: defaultControls().extend([new FullScreen()]),
    target: document.getElementById('map'),
    layers: [rasterLayer, vectorLayer],
    view: new View({
        center: asxLocation,
        zoom: 16
    }),
});

const popupDiv = document.getElementById('popup');
const popupContent = document.getElementById('popup-content');
const popupCloser = document.getElementById('popup-closer');
const popupOverlay = new Overlay({
    element: popupDiv,
    autoPan: true,
    autoPanAnimation: {
        duration: 250
    },
    positioning: 'bottom-center',
    stopEvent: false,
    offset: [-200, 100],
});
map.addOverlay(popupOverlay);

popupCloser.addEventListener('click',function(event) {
    popupOverlay.setPosition(undefined);
    popupCloser.blur();
    event.stopPropagation();
    event.stopImmediatePropagation();
});

map.on('click', function (event) {
    const feature = map.getFeaturesAtPixel(event.pixel)[0];
    if (map.hasFeatureAtPixel(event.pixel) === true) {
        const coordinates = feature.getGeometry().getCoordinates();
        popupOverlay.setPosition(coordinates);
        popupContent.innerHTML = '<div id="main-box">' +
                '<h1><span style="color: #e85b30;">workwear</span><span style="color: #000;">shop</span></h1>\n' +
                '<p>Theodor-Heuss-Straße 3<br />89340 Leipheim<br /></p>\n' +
                '<strong>Öffnungszeiten:</strong><br />\n' +
                '<p>Montag - Freitag: 9:00 - 18:00 Uhr<br />Samstag: 09:00 - 13:00 Uhr</p>\n' +
                '<p><strong><a class="wwslink" href="https://www.google.de/maps/dir/Arbeitsschutz-Express+GmbH,+Theodor-Heuss-Strasse,+89340+Leipheim" target="_blank">Routenplanung zum workwear Shop</a></strong></p>\n' +
                '</div>';
        popupDiv.style.visibility = 'visible';
    } else {
        popupOverlay.setPosition(undefined);
        popupCloser.blur();
    }
});

// change mouse cursor when over marker
map.on('pointermove', function (event){
    if (event.dragging === false) {
        map.getTargetElement().style.cursor = map.hasFeatureAtPixel(
            map.getEventPixel(event.originalEvent)
        ) === true ? 'pointer' : '';
    }
});
